/* eslint-disable react-hooks/exhaustive-deps */
import HeadNavi from "../homepage/components/MainNavi/Head/HeadNavi";
import { useEffect, useState } from "react";
import { itemMenu } from "../homepage/components/MainNavi/Tabs/TabsNaviV2";
import { soccerActice, soccer, baketballActive, baketball, rugbyActive, rugby, baseballActive, baseball, Dollar, calendar, TransactionWhite } from "../../components/imgExport";
import { SoccerStatement } from "./Component/SoccerStatement";
import { Layout } from "../../routes/Layout";
import useLoading from '../../hook/useLoading';
import useToast from '../../hook/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert } from '../../store/slices/toastSlice';
import * as myBetApi from '../../api/myBet/myBetApi';
import './style.css'
import useThrottle from "../../hook/useThrottle";
import dayjs, { Dayjs } from "dayjs";
import { Button, DatePicker, TimeRangePickerProps, Typography } from "antd";
import { getPresentMoney } from "../../api/billAcceptor/billAcceptorApi";
import { selectorUser, setUserMoney } from "../../store/slices/userSlice";
import { formatPrice } from "../../until";
import HeadNavi_V2 from "../homepage/components/MainNavi/Head/HeadNavi_V2";
import { FooterMobile } from "../../components/FooterMobile";
import ToastGlobal from "../../components/ToastGlobal";
const { RangePicker } = DatePicker;
export const Statement: React.FC = () => {
    const { presentMoney } = useSelector(selectorUser);
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    var startDateFirst = new Date(dayjs().startOf('week').toString()).getFullYear().toString() + "-" + (new Date(dayjs().startOf('week').toString()).getMonth() + 1 > 9 ? new Date(dayjs().startOf('week').toString()).getMonth() + 1 : "0" + new Date(dayjs().startOf('week').toString()).getMonth() + 1) + "-" + (new Date(dayjs().startOf('week').toString()).getDate() > 9 ? new Date(dayjs().startOf('week').toString()).getDate() : "0" + new Date(dayjs().startOf('week').toString()).getDate())
    var endDateFirst = new Date(dayjs().toString()).getFullYear().toString() + "-" + (new Date(dayjs().toString()).getMonth() + 1 > 9 ? new Date(dayjs().toString()).getMonth() + 1 : "0" + new Date(dayjs().toString()).getMonth() + 1) + "-" + (new Date(dayjs().toString()).getDate() > 9 ? new Date(dayjs().toString()).getDate() : "0" + new Date(dayjs().toString()).getDate())

    const [tournaments, setTournaments] = useState(itemMenu[0])
    const [chooseGame, setChooseGame] = useState<string>("All")
    const [startDate, setStartDate] = useState<string>(startDateFirst)
    const [endDate, setEndDate] = useState<string>(endDateFirst)
    const [limit, setLimit] = useState<number>(20)
    const [offset, setOffset] = useState<number>(0)
    const [listBill, setListBill] = useState<any>([])
    const [countAll, setCountAll] = useState<number>(0)
    const [countSoccer, setCountSoccer] = useState<number>(0)
    const [countBasketball, setCountBasketball] = useState<number>(0)
    const [countFootball, setCountFootball] = useState<number>(0)
    const [countBaseball, setCountBaseball] = useState<number>(0)

    const [money, setMoney] = useState("")
    const [time, setTime] = useState(0)
    const [openDrawerBestSlip, setOpenDrawerBestSlip] = useState(false)
    const [totalWinLose, setTotalWinLose] = useState<number>(0)
    const [commission, setCommission] = useState<number>(0)
    const [runningTotal, setRunningTotal] = useState<number>(0)

    const handelChooseTournaments = (value: itemMenu) => {
        const listParent = document.querySelectorAll(".ant-menu-parent-tournament.ant-menu-submenu-selected")
        if (listParent.length > 0) {
            listParent[0].classList.remove("ant-menu-submenu-selected")
        }

        const listChildren = document.querySelectorAll(".ant-menu-children-tournament.ant-menu-item-selected")
        if (listChildren.length > 0) {
            listChildren[0].classList.remove("ant-menu-item-selected")
        }

        setTournaments(value)
        if (value.id !== 9) {
            setTournaments(value)
            // gListGameByTournament(value.id)
            // gListAllBetMGM(value.name)
        }
        if (value.id === 0) {
            // gListOfGamesForTheHomepage()
        }
        window.location.href = value.href
        // navigator(value.href)
    }

    const getCurrentMoney = useThrottle(async () => {
        pushLoading(true);
        var timeTotal = 10
        const interval = setInterval(() => {
            if (timeTotal > 0) {
                timeTotal -= 1
                setTime(timeTotal)
            } else {
                clearInterval(interval)
            }
        }, 1000);
        const ketQua = await getPresentMoney();
        if (ketQua?.success) {
            dispatch(setUserMoney(ketQua.data.currentMoney))
            setMoney(ketQua.data.currentMoney)
        }
        setTimeout(() => {
            pushLoading(false);
        }, 1000)
    }, 10000)

    const sortedArray = (dataArray: any[]) => {
        dataArray.sort((a, b) => {
            return new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime();
        })
        console.log(sortedArray);
    }


    const getMyBetStatement = useThrottle(async () => {
        pushLoading(true)
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await myBetApi.myBetStatement(chooseGame, timeZone, limit, offset, startDate, endDate)
        if (ketQua?.success) {
            setListBill(ketQua.data)
        } else {
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        // 
        pushLoading(false)
    }, 100)

    const getCountBet = async () => {
        pushLoading(true)
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await myBetApi.myCountBetStatement(timeZone, startDate, endDate)
        if (ketQua?.success) {
            setCountAll(ketQua.data?.All ? ketQua.data?.All : 0)
            setCountSoccer(ketQua.data?.Soccer ? ketQua.data?.Soccer : 0)
            setCountBasketball(ketQua.data?.Basketball ? ketQua.data?.Basketball : 0)
            setCountFootball(ketQua.data?.Football ? ketQua.data?.Football : 0)
            setCountBaseball(ketQua.data?.Baseball ? ketQua.data?.Baseball : 0)
        } else {
            setCountAll(0)
            setCountSoccer(0)
            setCountBasketball(0)
            setCountFootball(0)
            setCountBaseball(0)
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        // 
        pushLoading(false)
    }


    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setStartDate && setStartDate(dateStrings[0])
            setEndDate && setEndDate(dateStrings[1])
        } else {
        }
    };
    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'This day', value: [dayjs('presets'), dayjs()] },
        { label: 'This week', value: [dayjs().startOf('week'), dayjs()] },
        { label: 'Last week', value: [dayjs().startOf('week').add(-7, 'd'), dayjs().endOf('week').add(-7, 'd')] },
        { label: 'Past 7 Day', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'This month', value: [dayjs().startOf('month'), dayjs()] },
        { label: 'Last month', value: [dayjs().startOf('month').add(-30, 'd'), (dayjs().startOf('month').add(-30, 'd')).endOf('month')] },
    ];

    useEffect(() => {
        getCurrentMoney()
    }, [chooseGame])

    useEffect(() => {
        getMyBetStatement()
        getCountBet()
    }, [startDate, endDate, chooseGame])



    useEffect(() => {
        var sumCommission = 0
        var sumTotalWinLose = 0
        var sumRunningTotal = 0
        if (listBill && listBill.length) {
            for (let index = 0; index < listBill.length; index++) {
                sumCommission = sumCommission + Number(listBill[index].comission)
                sumTotalWinLose = sumTotalWinLose + Number(listBill[index].winlose)
                sumRunningTotal = sumRunningTotal + Number(listBill[index].runningTotal)
            }
        }
        setCommission(Number(sumCommission))
        setTotalWinLose(Number(sumTotalWinLose))
        setRunningTotal(Number(sumRunningTotal))
    }, [listBill])

    return (
        <Layout>
            <div className='bg-[#131620]  overflow-hidden !h-[100vh] w-full xs:pt-[30px] xl:pt-0'>
                <div className="fixed z-[9999] w-[100vw] flex justify-center top-10 xs:left-0">
                    <ToastGlobal />
                </div>
                <HeadNavi_V2
                    openDrawer={openDrawerBestSlip}
                    closeDrawer={() => setOpenDrawerBestSlip(false)}
                    tournaments={tournaments}
                    setTournaments={handelChooseTournaments}
                />
                <div className="w-full h-full xs:mt-[55px] xl:mt-0 ">
                    <div className="w-full  xs:pl-0 xl:pl-[115px]  flex h-full">
                        <div className="flex flex-col w-full pl-1 pr-1 xs:pt-1 xl:pt-[55px]">
                            <div className="w-full ">
                                <div className="flex xs:flex-col xl:flex-row xl:justify-between items-center w-full border-l border-l-[#282C38] border-r border-r-[#282C38] border-t border-t-[#282C38] xs:px-2 xl:px-6">
                                    <div className="flex justify-between xs:pt-2 xl:pt-0 xs:w-full xl:w-auto">
                                        <div className="flex items-center rounded-lg border border-[#4B5563] px-4 ">
                                            <img src={calendar} alt="" />
                                            <RangePicker
                                                presets={rangePresets}
                                                onChange={onRangeChange}
                                                size="small"
                                                style={{ width: '220px' }}
                                                suffixIcon={false}
                                                defaultValue={[dayjs().startOf('week'), dayjs()]}
                                                value={[dayjs(startDate), dayjs(endDate)]}
                                                variant="borderless"
                                                className="custom-picker"
                                                popupClassName="custom-popup-picker"
                                                renderExtraFooter={() => <div className="flex w-full gap-4 pb-4">
                                                    <button className="w-full bg-[#131620] rounded-lg border border-[#C1C9E1] text-[#C1C9E1] text-xs font-medium py-2">
                                                        Cancel
                                                    </button>
                                                    <button className="w-full bg-[#D4B962] rounded-lg   text-[#FFFFFF] text-xs font-medium py-2">
                                                        OK
                                                    </button>
                                                </div>
                                                }
                                            />
                                        </div>
                                        <div className="xs:flex xl:hidden justify-end px-2 rounded-md w-full ">
                                            <div className="pl-1 cursor-pointer h-10 text-white flex items-center gap-2" onClick={() => window.location.reload()}>
                                                <img src={TransactionWhite} alt="Transaction" className='h-[20px] aspect-square' />
                                                <span className='text-xs !uppercase !text-white !m-0'>
                                                    Refresh
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex items-center xs:justify-between md:justify-center py-6  xs:gap-0 md:gap-[52px] xl:gap-0">
                                        <div className={`flex items-center gap-1 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-sm xl:text-sm xs:pr-0  xl2:pr-[26px] xl3:pr-[51px] text-[#C1C9E1] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'All' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                            onClick={() => { setChooseGame && setChooseGame('All'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                        >
                                            <div className="xs:pl-0 xl:pl-5">
                                                <img src={` ${chooseGame === 'All' ? soccerActice : soccer}`} alt="" className=" xs:hidden md:block img-hover-hidden "
                                                />
                                            </div>
                                            <div >
                                                <img src={`${soccerActice}`} alt="" className=" hidden img-hover " />
                                            </div>

                                            All
                                            <div className={`flex justify-center items-center xs:w-5 xs:h-5 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'All' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                {countAll}
                                            </div>
                                        </div>
                                        <div className={`flex items-center gap-1 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs xl:text-sm xs:pr-0  xl2:pr-[26px] xl3:pr-[51px] text-[#C1C9E1] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Soccer' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                            onClick={() => { setChooseGame && setChooseGame('Soccer'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                        >
                                            <div className="xs:pl-0 xl:pl-5">
                                                <img src={` ${chooseGame === 'Soccer' ? soccerActice : soccer}`} alt="" className="  xs:hidden md:block img-hover-hidden "
                                                />
                                            </div>
                                            <div >
                                                <img src={`${soccerActice}`} alt="" className=" hidden img-hover " />
                                            </div>

                                            soccer
                                            <div className={`flex justify-center items-center xs:w-5 xs:h-5 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Soccer' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                {countSoccer}
                                            </div>

                                        </div>
                                        <div className={`flex items-center gap-1 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs xl:text-sm  xs:pr-0  xl:pr-[26px] xl3:pr-[51px] text-[#C1C9E1] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Basketball' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                            onClick={() => { setChooseGame && setChooseGame('Basketball'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                        >
                                            <div className="xs:pl-0 xl:pl-5">
                                                <img src={`${chooseGame === 'Basketball' ? baketballActive : baketball}`} alt="" className=" xs:hidden md:block img-hover-hidden " />
                                            </div>

                                            <div >
                                                <img src={`${baketballActive}`} alt="" className=" hidden img-hover " />
                                            </div>

                                            basketball
                                            <div className={`flex justify-center items-center xs:w-5 xs:h-5 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Basketball' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                {countBasketball}
                                            </div>

                                        </div>
                                        <div className={`flex items-center gap-1 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs xl:text-sm  xs:pr-0  xl:pr-[26px] xl3:pr-[51px] text-[#C1C9E1] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Football' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                            onClick={() => { setChooseGame && setChooseGame('Football'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                        >
                                            <div className="xs:pl-0 xl:pl-5  ">
                                                <img src={`${chooseGame === 'Football' ? rugbyActive : rugby}`} alt="" className="  xs:hidden md:block img-hover-hidden" />
                                            </div>
                                            <div >
                                                <img src={`${rugbyActive}`} alt="" className="  hidden img-hover" />
                                            </div>

                                            football
                                            <div className={`flex justify-center items-center xs:w-5 xs:h-5 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Football' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                {countFootball}
                                            </div>

                                        </div>
                                        <div className={`flex items-center gap-1 uppercase xs:text-xs xl:text-sm text-[#C1C9E1] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Baseball' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                            onClick={() => { setChooseGame && setChooseGame('Baseball'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                        >
                                            <div className="xs:pl-0 xl:pl-5 ">
                                                <img src={`${chooseGame === 'Baseball' ? baseballActive : baseball}`} alt="" className="xs:hidden md:block img-hover-hidden  " />
                                            </div>
                                            <div >
                                                <img src={`${baseballActive}`} alt="" className=" hidden img-hover " />
                                            </div>

                                            baseball
                                            <div className={`flex justify-center items-center xs:w-5 xs:h-5 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Baseball' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                {countBaseball}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xs:hidden xl:flex justify-end px-2 rounded-md xs:w-full xl:w-auto xl:min-w-[300px] xxl:min-w-[400px] max-w-[300px]">
                                        <Button className="!bg-[#131620] !border-none"
                                            onClick={() => window.location.reload()}
                                        >
                                            <img src={TransactionWhite} alt="Transaction" className='h-[20px] aspect-square' />
                                            <span className='lg:!text-xs xl2:!text-sm  !uppercase !text-white !m-0'>
                                                Refresh
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                                <div className="xs:block md:hidden pl-2 pr-7  w-full border-l border-l-[#282C38] border-r border-r-[#282C38] ">
                                    <div className="flex  items-center justify-between border-dashed border-b border-b-[#D4B962] pb-2">
                                        <div className="flex items-center ">
                                            <span className="xs:text-xs sm:text-sm !text-[#E4E7F1] xs:pr-0 sm:pr-20 font-semibold">Date</span>
                                        </div>
                                        <div className=" grid grid-cols-3 ">
                                            <span className="xs:text-xs sm:text-sm !text-[#E4E7F1] font-semibold xs:w-[70px] sm:w-[150px] xs:flex xs:justify-center xs:items-center flex flex-col gap-1 items-center">Total Win/Lose
                                                <span className={`xs:text-xs sm:text-sm  ${(Number(totalWinLose) >= 0 && '!text-[#33E49A]') || (Number(totalWinLose) < 0 && ' !text-[#FF4A59]') || (' !text-[#33E49A]')}`}>${formatPrice(Math.abs(totalWinLose ? totalWinLose : 0))}
                                                </span>
                                            </span>
                                            <span className="text-center xs:text-xs sm:text-sm !text-[#E4E7F1] font-semibold xs:w-[70px] sm:w-[150px] xs:flex xs:justify-center xs:items-center flex flex-col gap-1 items-center">Commision
                                                <span className={`xs:text-xs sm:text-sm  ${(Number(commission) >= 0 && '!text-[#33E49A]') || (Number(commission) < 0 && ' !text-[#FF4A59]') || (' !text-[#33E49A]')}`}>${formatPrice(Math.abs(commission ? commission : 0))}
                                                </span>
                                            </span>
                                            <span className="xs:text-xs sm:text-sm !text-[#E4E7F1] font-semibold xs:w-[70px] sm:w-[150px] xs:flex xs:justify-center xs:items-center flex flex-col gap-1 items-center">Running Total
                                                <span className={`xs:text-xs sm:text-sm  ${(Number(runningTotal) >= 0 && '!text-[#33E49A]') || (Number(runningTotal) < 0 && ' !text-[#FF4A59]') || (' !text-[#33E49A]')}`}>${formatPrice(Math.abs(runningTotal ? runningTotal : 0))}
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <SoccerStatement
                                    money={Number(money)}
                                    listBill={listBill}
                                    startDate={startDate}
                                    endDate={endDate}
                                    time={time}
                                    setStartDate={(e: any) => setStartDate(e)}
                                    setEndDate={(e: any) => setEndDate(e)}
                                    getCurrentMoney={getCurrentMoney}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* footer mobile */}
                <div className="xs:block xl:hidden">
                    <FooterMobile
                        setOpenDrawerBestSlip={() => setOpenDrawerBestSlip(true)}
                    />
                </div>
            </div>
        </Layout >
    )
}