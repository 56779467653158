import { Form, Input } from "antd"
import { KB_best_sport_img_2, logoKB_2 } from "../../components/imgExport"
import LockIcon from '@mui/icons-material/Lock';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from "react";
import useLoading from '../../hook/useLoading';
import useToast from '../../hook/useToast';
import { useDispatch } from 'react-redux';
import useThrottle from "../../hook/useThrottle";
import * as loginApi from '../../api/login/loginApi';
import ToastGlobal from "../../components/ToastGlobal";
const FirstLogin: React.FC = () => {

    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [password, setPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [isComplexityValid, setIsComplexityValid] = useState(false);
    const [isMatch, setIsMatch] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [textToast, setTextToast] = useState('');
    const [statusToast, setStatusToast] = useState('warning');

    const changePassword = useThrottle(async () => {
        pushLoading(true)
        if (!password || !repeatNewPassword) {
            // dispatch(clearAlert())
            // pushToast("Please enter password", "warn", 10000);
            setShowToast(true)
            setTextToast("Please enter password.")
            setStatusToast("warn")
            pushLoading(false)
            return
        }
        const ketQua = await loginApi.changePasswordFirstLogin(password, repeatNewPassword)
        if (ketQua.success) {
            // dispatch(clearAlert())
            // pushToast(ketQua?.message ? ketQua?.message : "Change password success.", "success");
            setShowToast(true)
            setTextToast("Change password success.")
            setStatusToast("success")
            pushLoading(false)
            setTimeout(() => {
                window.location.href = "/Soccer_V2";
            }, 2000)
            return
        } else {
            // dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            setShowToast(true)
            setTextToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later")
            setStatusToast("warn")
            pushLoading(false)
            return
        }
    }, 1000)

    useEffect(() => {
        setIsLengthValid(password.length >= 8 && password.length <= 15);

        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
        setIsComplexityValid(regex.test(password));

        setIsMatch(password === repeatNewPassword);

    }, [password, repeatNewPassword]);

    return (
        <div className='h-[100vh] !w-full flex  !justify-center !items-center bg-login-new'>
            <div className='flex flex-col xs:w-[343px] xs:gap-3  md:w-[694px] md:gap-4  items-center rounded-[24px] border border-[#454449] bg-[#14171F]'>
                <div className='flex flex-col items-center xs:gap-3 md:gap-6'>
                    <div className="xs:pt-[24px]  md:pt-[44px] ">
                        <img src={logoKB_2} alt="" className="xs:w-[60px] xs:h-[60px]  md:w-[120px] md:h-[120px] " />
                    </div>
                    <img src={KB_best_sport_img_2} alt="" />
                    <div className="flex flex-col items-center gap-3">
                        <span className="xs:text-xs  md:text-lg  text-[#E4E7F1] uppercase">Welcome! <span className="text-[#D4B962]">For your security</span>,
                        </span>
                        <span className="xs:text-xs  md:text-sm text-[#E4E7F1] uppercase">please create a new password following these guidelines</span>
                    </div>
                </div>
                <ToastGlobal />
                <Form
                    name="Firstlogin"
                    initialValues={{ remember: true }}
                    form={form}
                    className="xs:w-[279px]  md:w-[356px] flex flex-col  "
                >
                    <div className="flex flex-col  w-full">
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                            className="custom-input rounded-lg border-4B5563"
                        >
                            <Input.Password
                                prefix={<LockIcon className="text-[#C1C9E1] xs:!text-xs  md:!text-2xl " />}
                                type="password"
                                placeholder="Your password"
                                size="large"
                                className="!text-[#C1C9E1] placehoder-C1C9E1"
                                iconRender={(visible) => (visible ?
                                    <RemoveRedEyeIcon className="text-[#C1C9E1] " /> :
                                    <VisibilityOffIcon className="text-[#C1C9E1] " />)}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="repeatNewPassword"
                            rules={[{ required: true, message: 'Please input your Repeat password!' }]}
                            className="custom-input rounded-lg border-4B5563">
                            <Input.Password
                                prefix={<LockIcon className="text-[#C1C9E1] xs:!text-xs  md:!text-2xl" />}
                                placeholder="Repeat password again"
                                size="large"
                                className="!text-[#C1C9E1] placehoder-C1C9E1"
                                iconRender={(visible) => (visible ?
                                    <RemoveRedEyeIcon className="text-[#C1C9E1] " /> :
                                    <VisibilityOffIcon className="text-[#C1C9E1] " />)}
                                value={repeatNewPassword}
                                onChange={(e) => setRepeatNewPassword(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                    <div className="flex flex-col gap-1 w-full px-[18px]">
                        <div className="flex items-center gap-1">
                            <CheckCircleIcon className={`text-xl ${isLengthValid ? 'text-[#C1C9E1]' : 'text-[#ff4a59]'}`} />
                            <span className={`text-xs ${isLengthValid ? 'text-[#C1C9E1]' : 'text-[#ff4a59]'}`}>
                                Must be 8-15 characters long
                            </span>
                        </div>
                        <div className="flex items-center gap-1">
                            <CheckCircleIcon className={`text-xl ${isComplexityValid ? 'text-[#C1C9E1]' : 'text-[#ff4a59]'}`} />
                            <span className={`text-xs ${isComplexityValid ? 'text-[#C1C9E1]' : 'text-[#ff4a59]'}`}>
                                Include both uppercase and lowercase letters (a-z, A-Z) and numbers (0-9) and special characters
                            </span>
                        </div>
                        <div className="flex items-center gap-1">

                            <CheckCircleIcon className={`text-xl ${isMatch ? 'text-[#C1C9E1]' : 'text-[#ff4a59]'}`} />
                            <span className={`text-xs  ${isMatch ? 'text-[#C1C9E1]' : 'text-[#ff4a59]'}`}>
                                Must not contain your login name, first name, or last name.
                            </span>
                        </div>

                    </div>
                </Form>
                <div className="flex gap-3 xs:w-[279px]  md:w-[356px] pb-8">
                    {/* <button
                        className="uppercase rounded-lg border border-[#E4E7F1] bg-[#141721] text-sm text-[#E4E7F1] py-[14px] w-full font-bold-GT-America"
                    >
                        skip for now
                    </button> */}
                    <button className={`uppercase rounded-lg  ${isLengthValid && isComplexityValid && isMatch ? "bg-[#DABE64] text-[#131620]" : "bg-[#C1C9E1] text-[#131620]"} text-sm  py-[14px] w-full font-bold-GT-America`}
                        onClick={() => {
                            // if (isLengthValid && isComplexityValid && isMatch)
                            changePassword()
                        }
                        }
                    >
                        Save New Password
                    </button>
                </div>
            </div>

        </div>
    )
}

export default FirstLogin