/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import BetSlipTab from '../../BetSlipTab/BetSlipTab';
import { InfoAddStrightSoccerTmp, InfoBetTeaserTMP, InfoParlaySoccerTmp } from '../../../../../api/betting/bettingApi';
import ItemStraightSoccer from './Component/ItemStraightSoccer';
import ItemParlaySoccer from './Component/ItemParlaySoccer';
import './styles.css'
import { Dollar, Trash, start } from '../../../../../components/imgExport';
import HeadRight from '../../MainNavi/Head/HeadRight';
import EmtyBetSlip from '../../../../../components/EmtyBetSlip';
import BetTabsSoccer from './BetTabSoccer';
import TotalStakeComponentV2 from '../../TotalStake/TotalStakeV2';
import { CloseCircleOutlined } from "@ant-design/icons"

interface ModalBetSlipProps {
    openDrawer: boolean
    closeDrawer: (value: boolean) => void

    menu?: any
    keyGameTab?: "gameOnline" | "gameOffline"
    keyBetTabs: string
    totalStake: number
    pointTeaser?: number
    listAddStrightBetsSlip?: InfoAddStrightSoccerTmp[]
    listParlayBetsSlip?: InfoParlaySoccerTmp[]
    listTeaserBetsSlip?: InfoBetTeaserTMP[]
    setKeyBetTabs: (value: string) => void
    rStrightBetsSlipByIndex: (value: number) => void
    clearAll: () => void;
    setListaddStrightBetsSlip: (value: InfoAddStrightSoccerTmp[]) => void
    setListParlayBetsSlip?: (value: InfoParlaySoccerTmp[]) => void
    setListTeaserBetsSlip?: (value: InfoBetTeaserTMP[]) => void
    setTotalStake: (index: number) => void
    setPointTeaser?: (value: number) => void
    chooseElement: string

    numberStake: number,

    isWarningBetParlay?: boolean
    showTotalStake: boolean,
    showTotalStake_V2: boolean,
    showTotalBet: boolean,

    totalOddsParlay?: number
    totalTParlay?: number
    totalAmountParlay?: number

    handleClick: (total: number) => void
    handleNumberClick: (total: number) => void
    handleChangeInput?: (total: number) => void
    showHideTotalStake: () => void,
    showHideTotalStake_V2: () => void,
    handleDeleteClick: (index: number) => void
    handleCheckBetStright: () => void
    handleCheckBetParlay?: () => void
    handleCheckQuantityMoneyStraight?: (keyBetTabs: number, total: number) => void
    hiddenBetPlaceSoccer?: boolean,
    minInputStake?: () => void
    maxInputStake?: () => void
    minBet?: number
    maxBet?: number
}
const ModalBetSlip: React.FC<ModalBetSlipProps> = ({
    openDrawer,
    closeDrawer,
    menu, keyGameTab, keyBetTabs,
    listAddStrightBetsSlip, listParlayBetsSlip, listTeaserBetsSlip, totalStake, numberStake, isWarningBetParlay, showTotalStake, showTotalBet, totalOddsParlay, totalTParlay, totalAmountParlay, hiddenBetPlaceSoccer, chooseElement, showTotalStake_V2,
    minBet, maxBet,

    showHideTotalStake_V2, setKeyBetTabs, rStrightBetsSlipByIndex, clearAll, setListaddStrightBetsSlip, setListParlayBetsSlip, setListTeaserBetsSlip, setTotalStake, setPointTeaser, handleClick, handleNumberClick, handleChangeInput, showHideTotalStake, handleDeleteClick, handleCheckBetStright, handleCheckBetParlay, handleCheckQuantityMoneyStraight, minInputStake, maxInputStake,
}) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [settingClicked, setSettingClicked] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const settingbet = menu?.map((item: any) => item.key)

    const items: TabsProps['items'] = keyGameTab === "gameOffline" ? ([
        {
            key: '1',
            label: settingbet?.includes('1') ? 'Straight' : '',
            children:
                <div className={`betTabs flex flex-col gap-2 ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
                    {
                        listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, i) => (
                            <ItemStraightSoccer
                                key={i}
                                index={i}
                                item={item}
                                listAddStrightBetsSlip={listAddStrightBetsSlip}
                                setListaddStrightBetsSlip={setListaddStrightBetsSlip}
                                rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                                setTotalStake={setTotalStake}
                            />
                        ))
                    }

                    {/* <div className='flex justify-between pt-3 absolute  bottom-0 w-full bg-white'>
                        <Checkbox
                            checked={isAllCheckedStright}
                            onChange={(e) => handleCheckAll(e.target.checked)}
                        ></Checkbox>
                        <div className='flex flex-row-reverse'>
                            <Button
                                className='!flex items-center pl-2 pr-1'
                                type="text"
                                onClick={clearAll}
                            >
                                <DeleteOutlined
                                    className='text-[#000000]'
                                />
                                <span className='text-black font-[500]'
                                >Clear All
                                </span>
                            </Button>
                        </div>
                    </div> */}
                    <BetSlipTab showTabs={false} />
                </div>,
        },
        {
            key: '2',
            label: settingbet?.includes('2') ? 'Parlay' : '',
            children: <div className={`betTabs pl-2 flex flex-col gap-2 ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
                {
                    listParlayBetsSlip && listParlayBetsSlip.map((item, i) => (
                        <ItemParlaySoccer
                            key={i}
                            index={i}
                            item={item}
                            listParlayBetsSlip={listParlayBetsSlip}
                            rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                            setListParlayBetsSlip={setListParlayBetsSlip}
                        // setTotalStake={setTotalStake}
                        />
                    ))}
                <BetSlipTab showTabs={false} />
            </div>,
        },
    ]) : ([
        {
            key: '1',
            label: settingbet?.includes('1') ? 'Straight' : '',
            children:
                <div className={`betTabs ${settingClicked && chooseElement === '6' ? 'bg-[#D4B962]' : ''}`}>
                    {
                        listAddStrightBetsSlip && listAddStrightBetsSlip.map((item, i) => (
                            <ItemStraightSoccer
                                key={i}
                                index={i}
                                item={item}
                                listAddStrightBetsSlip={listAddStrightBetsSlip}
                                setListaddStrightBetsSlip={setListaddStrightBetsSlip}
                                rStrightBetsSlipByIndex={rStrightBetsSlipByIndex}
                                setTotalStake={setTotalStake}
                            />
                        ))
                    }
                    <BetSlipTab showTabs={false} />
                </div>,
        }])

    return (
        <Drawer
            open={openDrawer}
            onClose={() => closeDrawer(false)}
            closeIcon={false}
            placement="bottom"
            className="!bg-[#131620] p-none-drawer xs:!block  xl:!hidden !h-full !overflow-hidden"
            maskClassName='xs:!block  xl:!hidden'
        >
            <div className="overflow-hidden  h-full">
                <div className={`flex flex-col  border border-[#202531] bg-[#131620] w-full  ${listAddStrightBetsSlip && listAddStrightBetsSlip.length > 0 || listParlayBetsSlip && listParlayBetsSlip.length > 0 ? 'justify-start ' : 'justify-between h-full'}`}>
                    <div className="flex flex-col w-full">
                        <div className='bg-gradient-A98F34 flex justify-between h-[38px] px-3 w-full'>
                            <div className='flex items-center gap-2 w-full'>
                                <img src={Dollar} alt="" className="w-[30px] h-[30px]" />
                                <span className='font-bold-GT-America text-sm text-white uppercase'>Balance</span>
                                <HeadRight />
                                <CloseCircleOutlined
                                    className="text-red-600 cursor-pointer text-[24px]"
                                    onClick={() => closeDrawer(false)}
                                />
                            </div>
                        </div>
                        <span className='flex justify-between p-3'>
                            <div className="flex items-center gap-2">
                                <img src={start} alt="Crown" className='w-[35px] h-[35px]' />
                                <p className="font-bold-GT-America text-sm text-white uppercase">Bet Slip</p>
                            </div>
                            <div className='flex items-center gap-1 '>
                                <div className={`${listAddStrightBetsSlip && listAddStrightBetsSlip.length > 0 || listParlayBetsSlip && listParlayBetsSlip.length > 0 ? 'flex items-center gap-1' : 'hidden '}`}
                                    onClick={clearAll}
                                >
                                    <img src={Trash} alt="" />
                                    <span className='text-sm text-white uppercase fw-[500]'>Remove All</span>
                                </div>

                                <span className={`h-5 w-5 rounded-[50%] xs:text-xs  xl2:text-base  flex items-center justify-center ${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? 'bg-[#202531] text-white' : 'bg-[#D4B962] text-[#131620]'}`}>
                                    {keyBetTabs === '1' ? listAddStrightBetsSlip && listAddStrightBetsSlip.length.toString() : listAddStrightBetsSlip && listAddStrightBetsSlip.length.toString()}
                                </span>
                            </div>
                        </span>
                        <div className={`tab-bet-soccer ${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 && listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? '' : 'tab-bet-visible-soccer'}`}>
                            <Tabs
                                items={items}
                                activeKey={keyBetTabs}
                                onChange={(e) => {
                                    setKeyBetTabs(e.toString());
                                }}
                                className={`${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 && listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? 'tab-bet-home-none' : 'tab-bet-visible-soccer'}`}
                            />
                            <div className={`${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 && listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 ? 'block' : 'hidden'}`}>
                                <EmtyBetSlip activeKey={keyBetTabs} />
                            </div>

                        </div>
                    </div>
                    <div className={`${listAddStrightBetsSlip && listAddStrightBetsSlip.length === 0 && listParlayBetsSlip && listParlayBetsSlip.length === 0 ? '' : 'hidden'} border-t border-[#202531] flex flex-col gap-3 px-6 pb-5`}>
                        <div className='flex justify-between pt-3'>
                            <div className='flex flex-col gap-4'>
                                <span className='text-base fw-[400] text-[#E4E7F1]'>
                                    Total stake
                                </span>
                                <span className='text-base fw-[400] text-[#E4E7F1]'>-</span>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <span className='text-base fw-[400] text-[#E4E7F1]'>
                                    Total win
                                </span>
                                <span className='text-base fw-[400] text-[#E4E7F1]'>-</span>
                            </div>
                        </div>
                        <button className={`font-bold-GT-America rounded-[2px]  uppercase w-full  py-[10px] ${listAddStrightBetsSlip && listAddStrightBetsSlip.length > 0 ? 'bg-[#D4B962] text-[#20273D]' : 'bg-[#E4E7F1] text-[#666E97]'}`}>
                            Place bet
                        </button>
                    </div>

                </div>
                <TotalStakeComponentV2

                    keyBetTabs={keyBetTabs}
                    showTotalBet={showTotalBet}
                    showTotalStake={showTotalStake}
                    showTotalStake_V2={showTotalStake_V2}
                    isWarningBetParlay={isWarningBetParlay}
                    totalOddsParlay={totalOddsParlay}
                    totalTParlay={totalTParlay}
                    totalAmountParlay={totalAmountParlay}
                    numberStake={totalStake}
                    listAddStrightBetsSlip={listAddStrightBetsSlip ?? []}
                    listParlayBetsSlip={listParlayBetsSlip}

                    handleClick={handleClick}
                    handleNumberClick={handleNumberClick}
                    handleChangeInput={handleChangeInput}
                    minInputStake={minInputStake}
                    maxInputStake={maxInputStake}
                    handleDeleteClick={handleDeleteClick}
                    showHideTotalStake={showHideTotalStake}
                    showHideTotalStake_V2={showHideTotalStake_V2}
                    handleCheckQuantityMoneyStraight={handleCheckQuantityMoneyStraight}
                    handleCheckBetStright={handleCheckBetStright}
                    handleCheckBetParlay={handleCheckBetParlay}
                    hiddenBetPlaceSoccer={hiddenBetPlaceSoccer}
                    minBet={minBet}
                    maxBet={maxBet}
                />
            </div>
        </Drawer>


    );
};

export default ModalBetSlip;