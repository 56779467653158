/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import HeadNavi from "../homepage/components/MainNavi/Head/HeadNavi";
import { useEffect, useState } from "react";
import { itemMenu } from "../homepage/components/MainNavi/Tabs/TabsNaviV2";
import './style.css'
import { Running } from "./Running";
import { Settled } from "./Settled";
import { Layout } from "../../routes/Layout";
import useLoading from '../../hook/useLoading';
import useToast from '../../hook/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert } from '../../store/slices/toastSlice';
import * as myBetApi from '../../api/myBet/myBetApi';
import { selectorUser } from "../../store/slices/userSlice"
import { baketball, baketballActive, baseball, baseballActive, rugby, rugbyActive, soccer, soccerActice, TransactionWhite } from "../../components/imgExport";
// import useThrottle from "../../hook/useThrottle";
// import { getPresentMoney } from "../../api/billAcceptor/billAcceptorApi";
// import { setUserMoney } from "../../store/slices/userSlice";
// import { formatPrice } from "../../until";
import io from 'socket.io-client';
import { API_URL } from "../../env";
import { Button, Typography } from "antd";
import HeadNavi_V2 from "../homepage/components/MainNavi/Head/HeadNavi_V2";
import { FooterMobile } from "../../components/FooterMobile";
import ToastGlobal from "../../components/ToastGlobal";
export const Mybet: React.FC = () => {
    const [socket, setSocket] = useState<any>(null);
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    const [tournaments, setTournaments] = useState(itemMenu[0])
    const [chooseTabMyBet, setChooseTabMyBet] = useState<number>(1)
    const [totalRunning, setTotalRunning] = useState<number>(0)
    const [totalSettled, setTotalSettled] = useState<number>(0)
    const { userId, presentMoney } = useSelector(selectorUser);


    const [chooseGame, setChooseGame] = useState<string>('All')
    const [limit, setLimit] = useState<number>(20)
    const [offset, setOffset] = useState<number>(0)
    const [billSocket, setBillSocket] = useState<any[]>([])
    const [billSocketAll, setBillSocketAll] = useState<any[]>([])
    const [runningBill, setRunningBill] = useState<any[]>([])
    const [rejectBill, setRejectBill] = useState<any[]>([])
    const [listBill, setListBill] = useState<any>([])
    const [offsetSettled, setOffsetSettled] = useState<number>(0)
    const [listBillSettled, setListBillSettled] = useState<any>([])
    const [countAll, setCountAll] = useState<number>(0)
    const [countSoccer, setCountSoccer] = useState<number>(0)
    const [countBasketball, setCountBasketball] = useState<number>(0)
    const [countFootball, setCountFootball] = useState<number>(0)
    const [countBaseball, setCountBaseball] = useState<number>(0)
    const [openDrawerBestSlip, setOpenDrawerBestSlip] = useState(false)

    const [money, setMoney] = useState("")
    const [time, setTime] = useState(0)

    const handelChooseTournaments = (value: itemMenu) => {
        const listParent = document.querySelectorAll(".ant-menu-parent-tournament.ant-menu-submenu-selected")
        if (listParent.length > 0) {
            listParent[0].classList.remove("ant-menu-submenu-selected")
        }

        const listChildren = document.querySelectorAll(".ant-menu-children-tournament.ant-menu-item-selected")
        if (listChildren.length > 0) {
            listChildren[0].classList.remove("ant-menu-item-selected")
        }

        setTournaments(value)
        if (value.id !== 9) {
            setTournaments(value)
        }
        if (value.id === 0) {
        }
        window.location.href = value.href
    }

    const getCountBet = async () => {
        pushLoading(true)
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await myBetApi.myCountBet(chooseTabMyBet === 1 ? "running" : "settled", timeZone)
        if (ketQua?.success) {
            setCountAll(ketQua.data?.All ? ketQua.data?.All : 0)
            setCountSoccer(ketQua.data?.Soccer ? ketQua.data?.Soccer : 0)
            setCountBasketball(ketQua.data?.Basketball ? ketQua.data?.Basketball : 0)
            setCountFootball(ketQua.data?.Football ? ketQua.data?.Football : 0)
            setCountBaseball(ketQua.data?.Baseball ? ketQua.data?.Baseball : 0)
        } else {
            setCountAll(0)
            setCountSoccer(0)
            setCountBasketball(0)
            setCountFootball(0)
            setCountBaseball(0)
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        // 
        pushLoading(false)
    }

    const getMyBetRunning = async () => {
        pushLoading(true)
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await myBetApi.myBetRunning(chooseGame, timeZone, limit, offset)
        if (ketQua?.success) {
            setTotalRunning(ketQua.total ? ketQua.total : 0)
            setListBill(ketQua.data)
        } else {
            setTotalRunning(0)
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        // 
        pushLoading(false)
    }

    const getMyBetSettled = async () => {
        pushLoading(true)
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await myBetApi.myBetSettled(chooseGame, timeZone, limit, offsetSettled)
        if (ketQua?.success) {
            setTotalSettled(ketQua.total ? ketQua.total : 0)
            setListBillSettled(ketQua.data)
        } else {
            setTotalSettled(0)
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        // 
        pushLoading(false)
    }


    // const getCurrentMoney = useThrottle(async () => {
    //     pushLoading(true);
    //     var timeTotal = 10
    //     const interval = setInterval(() => {
    //         if (timeTotal > 0) {
    //             timeTotal -= 1
    //             setTime(timeTotal)
    //         } else {
    //             clearInterval(interval)
    //         }
    //     }, 1000);
    //     const ketQua = await getPresentMoney();
    //     if (ketQua?.success) {
    //         dispatch(setUserMoney(ketQua.data.currentMoney))
    //         setMoney(ketQua.data.currentMoney)
    //     }
    //     setTimeout(() => {
    //         pushLoading(false);
    //     }, 1000)
    // }, 10000)

    useEffect(() => {
        getCountBet()
    }, [chooseTabMyBet])

    useEffect(() => {
        getMyBetSettled()
        getMyBetRunning()
        setBillSocket([])
        setBillSocketAll([])
    }, [chooseGame])

    useEffect(() => {
        getMyBetRunning()
    }, [offset])

    useEffect(() => {
        getMyBetSettled()
    }, [setOffsetSettled])

    useEffect(() => {
        listBill.map((item: any, index: number) => {
            if (runningBill.includes(item.id)) {
                listBill[index].status = 1
            }
            if (rejectBill.includes(item.id)) {
                listBill[index].status = 3
            }
        })
    }, [rejectBill, runningBill])

    useEffect(() => {
        const socketInstance = io(API_URL);
        setSocket(socketInstance);

        socketInstance.on('connect', () => {
            console.log('Connected to server get bill Live');
        });
        socketInstance.on('bet-validation', async (data: any) => {
            if (Number(data.idUser) === Number(userId)) {
                if (data.success) {
                    var test = data.idBill
                    setRunningBill((datatest) => [test, ...datatest])
                } else {
                    var test1 = data.idBill
                    setRejectBill((datatest) => [test1, ...datatest])
                }
            }
        });
        socketInstance.on('bet-placed', async (data: any) => {
            if (data?.type === "Parlay") {
                if (data?.data?.[0]?.sportName.toLowerCase() === chooseGame.toLowerCase() || chooseGame.toLowerCase() === "all") {
                    if (Number(data.idUser) === Number(userId)) {
                        var dataSocket = data
                        dataSocket.idBill = data.idBill
                        dataSocket.status = 0
                        setBillSocket((datadataSocket) => [dataSocket, ...datadataSocket])
                    }
                }
                if (Number(data.idUser) === Number(userId)) {
                    var dataSocketAll = data
                    dataSocketAll.idBill = data.idBill
                    dataSocketAll.status = 0
                    setBillSocketAll((datadataSocketAll) => [dataSocketAll, ...datadataSocketAll])
                }
            } else {
                if (data?.data?.[0]?.sportName.toLowerCase() === chooseGame.toLowerCase() || chooseGame.toLowerCase() === "all") {
                    if (Number(data.idUser) === Number(userId)) {
                        data?.data && data.data.forEach((element: any, index: number) => {
                            var dataSocket = data.data[index]
                            dataSocket.idBill = data.idBill
                            dataSocket.status = 0
                            setBillSocket((datadataSocket) => [dataSocket, ...datadataSocket])
                        });
                    }
                }
                if (Number(data.idUser) === Number(userId)) {
                    data?.data && data.data.forEach((element: any, index: number) => {
                        var dataSocketAll = data.data[index]
                        dataSocketAll.idBill = data.idBill
                        dataSocketAll.status = 0
                        setBillSocketAll((datadataSocketAll) => [dataSocketAll, ...datadataSocketAll])
                    });
                }
            }

        });
        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chooseGame]);

    return (
        <Layout>
            <div className='bg-[#131620]  overflow-hidden !h-[100vh] w-full xs:pt-[30px] xl:pt-0'>
                <div className="fixed z-[9999] w-[100vw] flex justify-center top-10 xs:left-0">
                    <ToastGlobal />
                </div>
                <HeadNavi_V2
                    openDrawer={openDrawerBestSlip}
                    closeDrawer={() => setOpenDrawerBestSlip(false)}
                    tournaments={tournaments}
                    setTournaments={handelChooseTournaments}
                />
                <div className="w-full h-full xs:mt-[50px] xl:mt-0 ">
                    <div className="w-full flex h-full xs:pl-0 xl:pl-[115px]">
                        <div className=" flex flex-col  w-full pl-1 pr-1 xs:pt-1 xl:pt-[55px]">
                            <div className="flex xs:flex-col xl:flex-row xl:justify-between items-center w-full border-l border-l-[#282C38] border-r border-r-[#282C38] border-t border-t-[#282C38] xs:px-2 xl:px-6">
                                <div className="flex xs:justify-between xl:justify-start xs:border-0 xl:border xl:border-[#212632] xs:px-0 xl:px-2 rounded-md xs:w-full xl:w-auto xs:pt-2 xl:pt-0">
                                    <div className="flex xs:border xs:border-[#212632] xl:border-0 rounded-md px-2">
                                        <div className={`flex justify-center items-center gap-[10px] cursor-pointer `}
                                            onClick={() => setChooseTabMyBet(1)}
                                        >
                                            <span className={`xs:text-xs md:text-base font-medium py-2 whitespace-nowrap uppercase ${chooseTabMyBet === 1 ? "text-[#D4B962]" : "text-[#E4E7F1]"}`}>
                                                Running
                                            </span>
                                            <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseTabMyBet === 1 ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                {totalRunning + billSocket.length}
                                            </div>
                                        </div>
                                        <div className=" flex justify-center items-center gap-[10px] xs:pl-6 md:pl-12 cursor-pointer"
                                            onClick={() => setChooseTabMyBet(2)}
                                        >
                                            <span className={`xs:text-xs md:text-base font-medium py-2 whitespace-nowrap uppercase ${chooseTabMyBet === 2 ? "text-[#D4B962]" : "text-[#E4E7F1]"}`}>
                                                Settled
                                            </span>
                                            <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseTabMyBet === 2 ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                {totalSettled}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="xs:flex xl:hidden justify-end px-2 rounded-md w-full ">
                                        <div className="pl-1 cursor-pointer h-10 text-white flex items-center xs:gap-1 xl:gap-2" onClick={() => window.location.reload()}>
                                            <img src={TransactionWhite} alt="Transaction" className='h-[20px] aspect-square' />
                                            <span className='text-xs !uppercase !text-white !m-0'>
                                                Refresh
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    chooseTabMyBet === 1 ? (
                                        <div className="w-full xs:gap-3 md:gap-[52px] xl:gap-0 flex items-center xs:px-6 md:px-0 xs:justify-center xl:justify-between xl3:justify-center py-6 ">
                                            <div className={`flex items-center xs:gap-1 xl:gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm xs:pr-0  xl:pr-[26px] xl3:pr-[51px] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'All' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                                onClick={() => { setChooseGame && setChooseGame('All'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                            >
                                                <img src={` ${chooseGame === 'All' ? soccerActice : soccer}`} alt="" className="xs:pl-0 xl:pl-8  img-hover-hidden xs:hidden xl:block"
                                                />
                                                <img src={`${soccerActice}`} alt="" className="xs:pl-0 xl:pl-8 hidden img-hover xs:hidden " />
                                                All
                                                <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'All' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                    {countAll + billSocketAll.length}
                                                </div>
                                            </div>
                                            <div className={`flex items-center xs:gap-1 xl:gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm xs:pr-0  xl:pr-[26px] xl3:pr-[51px] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Soccer' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                                onClick={() => { setChooseGame && setChooseGame('Soccer'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                            >
                                                <img src={` ${chooseGame === 'Soccer' ? soccerActice : soccer}`} alt="" className="xs:pl-0 xl:pl-8  img-hover-hidden xs:hidden xl:block"
                                                />
                                                <img src={`${soccerActice}`} alt="" className="xs:pl-0 xl:pl-8 hidden img-hover xs:hidden" />
                                                soccer
                                                <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Soccer' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                    {countSoccer + billSocketAll.filter((item) => item.sportName === "Soccer").length}
                                                </div>
                                            </div>
                                            <div className={`flex items-center xs:gap-1 xl:gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm  xs:pr-0  xl:pr-[26px] xl3:pr-[51px] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Basketball' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                                onClick={() => { setChooseGame && setChooseGame('Basketball'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                            >
                                                <img src={`${chooseGame === 'Basketball' ? baketballActive : baketball}`} alt="" className="xs:pl-0 xl:pl-8 img-hover-hidden xs:hidden xl:block" />
                                                <img src={`${baketballActive}`} alt="" className="xs:pl-0 xl:pl-8 hidden img-hover xs:hidden" />
                                                basketball
                                                <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Basketball' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                    {countBasketball + billSocketAll.filter((item) => item.sportName === "Basketball").length}
                                                </div>
                                            </div>
                                            <div className={`flex items-center xs:gap-1 xl:gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm  xs:pr-0  xl:pr-[26px] xl3:pr-[51px] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Football' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                                onClick={() => { setChooseGame && setChooseGame('Football'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                            >
                                                <img src={`${chooseGame === 'Football' ? rugbyActive : rugby}`} alt="" className="xs:pl-0 xl:pl-8 img-hover-hidden xs:hidden xl:block" />
                                                <img src={`${rugbyActive}`} alt="" className="xs:pl-0 xl:pl-8 hidden img-hover xs:hidden" />
                                                football
                                                <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Football' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                    {countFootball + billSocketAll.filter((item) => item.sportName === "Football").length}
                                                </div>
                                            </div>
                                            <div className={`flex items-center xs:gap-1 xl:gap-2 uppercase xs:text-xs md:text-sm text-[#C1C9E1] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Baseball' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                                onClick={() => { setChooseGame && setChooseGame('Baseball'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                            >
                                                <img src={`${chooseGame === 'Baseball' ? baseballActive : baseball}`} alt="" className="xs:pl-0 xl:pl-8 img-hover-hidden xs:hidden xl:block" />
                                                <img src={`${baseballActive}`} alt="" className="xs:pl-0 xl:pl-8 hidden img-hover xs:hidden" />
                                                baseball
                                                <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Baseball' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                    {countBaseball + billSocketAll.filter((item) => item.sportName === "Baseball").length}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                        : (
                                            <div className="w-full xs:gap-3 md:gap-[52px] xl:gap-0 flex items-center xs:px-6 md:px-0 xs:justify-center xl:justify-between xl3:justify-center py-6 ">
                                                <div className={`flex items-center xs:gap-1 xl:gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm xs:pr-0  xl:pr-[26px] xl3:pr-[51px] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'All' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                                    onClick={() => { setChooseGame && setChooseGame('All'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                                >
                                                    <img src={` ${chooseGame === 'All' ? soccerActice : soccer}`} alt="" className="xs:pl-0 xl:pl-8  block img-hover-hidden xs:hidden xl:block"
                                                    />
                                                    <img src={`${soccerActice}`} alt="" className="xs:pl-0 xl:pl-8 hidden img-hover xs:hidden" />
                                                    All
                                                    <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'All' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                        {countAll}
                                                    </div>
                                                </div>
                                                <div className={`flex items-center xs:gap-1 xl:gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm xs:pr-0  xl:pr-[26px] xl3:pr-[51px] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Soccer' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                                    onClick={() => { setChooseGame && setChooseGame('Soccer'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                                >
                                                    <img src={` ${chooseGame === 'Soccer' ? soccerActice : soccer}`} alt="" className="xs:pl-0 xl:pl-8  block img-hover-hidden xs:hidden xl:block"
                                                    />
                                                    <img src={`${soccerActice}`} alt="" className="xs:pl-0 xl:pl-8 hidden img-hover xs:hidden" />
                                                    soccer
                                                    <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Soccer' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                        {countSoccer}
                                                    </div>
                                                </div>
                                                <div className={`flex items-center xs:gap-1 xl:gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm  xs:pr-0  xl:pr-[26px] xl3:pr-[51px] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Basketball' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                                    onClick={() => { setChooseGame && setChooseGame('Basketball'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                                >
                                                    <img src={`${chooseGame === 'Basketball' ? baketballActive : baketball}`} alt="" className="xs:pl-0 xl:pl-8 block img-hover-hidden xs:hidden xl:block" />
                                                    <img src={`${baketballActive}`} alt="" className="xs:pl-0 xl:pl-8 hidden img-hover xs:hidden " />
                                                    basketball
                                                    <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Basketball' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                        {countBasketball}
                                                    </div>
                                                </div>
                                                <div className={`flex items-center xs:gap-1 xl:gap-2 xs:border-0 xl:border-r xl:border-r-[#212632] uppercase xs:text-xs md:text-sm  xs:pr-0  xl:pr-[26px] xl3:pr-[51px] hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Football' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                                    onClick={() => { setChooseGame && setChooseGame('Football'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                                >
                                                    <img src={`${chooseGame === 'Football' ? rugbyActive : rugby}`} alt="" className="xs:pl-0 xl:pl-8 block img-hover-hidden xs:hidden xl:block" />
                                                    <img src={`${rugbyActive}`} alt="" className="xs:pl-0 xl:pl-8 hidden img-hover xs:hidden" />
                                                    football
                                                    <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Football' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                        {countFootball}
                                                    </div>
                                                </div>
                                                <div className={`flex items-center xs:gap-1 xl:gap-2  uppercase xs:text-xs md:text-sm  hover:text-[#D4B962] cursor-pointer div-hover ${chooseGame === 'Baseball' ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                                    onClick={() => { setChooseGame && setChooseGame('Baseball'); setLimit && setLimit(20); setOffset && setOffset(0); }}
                                                >
                                                    <img src={`${chooseGame === 'Baseball' ? baseballActive : baseball}`} alt="" className="xs:pl-0 xl:pl-8 block img-hover-hidden xs:hidden xl:block" />
                                                    <img src={`${baseballActive}`} alt="" className="xs:pl-0 xl:pl-8 hidden img-hover xs:hidden" />
                                                    baseball
                                                    <div className={`flex justify-center items-center xs:w-4 xs:h-4 md:w-5 md:h-5 rounded-full xs:text-[10px] md:text-xs  font-medium  ${chooseGame === 'Baseball' ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                                        {countBaseball}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                }
                                <div className="xs:hidden xl:flex justify-end px-2 rounded-md xs:w-full xl:w-auto xl:min-w-[150px] xxl:min-w-[400px] max-w-[300px]">
                                    <div className="pl-1 cursor-pointer h-10 text-white flex items-center xs:gap-1 xl:gap-2" onClick={() => window.location.reload()}>
                                        <img src={TransactionWhite} alt="Transaction" className='h-[20px] aspect-square' />
                                        <span className='lg:!text-xs xl2:!text-sm  !uppercase !text-white !m-0'>
                                            Refresh
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {
                                chooseTabMyBet === 1 && (
                                    <Running
                                        billSocket={billSocket}
                                        runningBill={runningBill}
                                        rejectBill={rejectBill}
                                        totalRunning={totalRunning}
                                        listBill={listBill}
                                        setOffset={(e) => setOffset(e)}
                                    />
                                )
                            }
                            {
                                chooseTabMyBet === 2 && (
                                    <Settled
                                        totalSettled={totalSettled}
                                        listBill={listBillSettled}
                                        setLimit={(e) => setLimit(e)}
                                    />
                                )
                            }
                        </div>

                    </div>
                </div>
                {/* footer mobile */}
                <div className="xs:block xl:hidden">
                    <FooterMobile
                        setOpenDrawerBestSlip={() => setOpenDrawerBestSlip(true)}
                    />
                </div>
            </div>
        </Layout>
    )
}