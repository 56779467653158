import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotalMatch } from "../axiosClient";
export interface InfoBetMGM {
  optionMarkets: OptionMarket[]
  games: Game[]
  participants: Participant[]
  id: string
  name: Name8
  description?: Description
  sourceId: number
  source: string
  fixtureType: string
  context: string
  addons: Addons
  stage: string
  groupId: number
  liveType: string
  liveAlert: boolean
  startDate: string
  showTime: any
  showTimeNow: any
  subtractTimeClient: any
  cutOffDate: string
  sport: Sport
  competition: Competition
  region: Region
  viewType: string
  isOpenForBetting: boolean
  isVirtual: boolean
  taggedLocations: any[]
  totalMarketsCount: number
  conferences: any[]
  marketGroups: MarketGroups
  priceBoostCount: number
  teaserbet?: Teaserbet
  linkedTv1EventIds: any[]
  tv2RelatedFixtureId?: string
  hybridFixtureData?: HybridFixtureData
  scoreboard?: Scoreboard
}

export interface Scoreboard {
  totalPoints: TotalPoints
  scoreDetailed?: TotalPoints
  matchType: number
  sportId: number
  period: string
  periodId: number
  points: any[]
  turn: string
  score: string
  indicator: string
  started: boolean
}

export interface TotalPoints {
  player1: Player
  player2: Player
}

export interface Player {
  "1": number
  "3": number
  "255": number
}

export interface OptionMarket {
  id: number
  name: Name
  status: string
  options: Option[]
  parameters: Parameter[]
  grouping: Grouping
  spread: number
  balanced: number
  isMain: boolean
  templateCategory: TemplateCategory
  comboPrevention: string
  minCombo: number
  resultOrder: string
  source: string
  isBetBuilder: boolean
  attr?: string
}

export interface Name {
  value: string
  sign: string
}

export interface Option {
  id: number
  status: string
  name: Name2
  sourceName?: SourceName
  price: Price
  totalsPrefix?: string
  attr?: string
}

export interface Name2 {
  value: string
  sign: string
}

export interface SourceName {
  value: string
  sign: string
}

export interface Price {
  id: number
  numerator: number
  denominator: number
  odds: number
  americanOdds: number
  priceHistory: any[]
}

export interface Parameter {
  key: string
  type: string
  value: string
}

export interface Grouping {
  gridGroups: string[]
  detailed: Detailed[]
}

export interface Detailed {
  subIndex?: number
  marketTabId: number
  optionColumnId?: number
  name?: string
  displayType: string
  marketGroupId: string
  marketGroupItemId: string
  orderType: string
}

export interface TemplateCategory {
  id: number
  name: Name3
  category: string
}

export interface Name3 {
  value: string
  sign: string
}

export interface Game {
  id: number
  name: Name4
  results: Result[]
  templateId: number
  categoryId: number
  resultOrder: string
  combo1: string
  combo2: string
  visibility: string
  balanced: number
  spread: number
  category: string
  templateCategory: TemplateCategory2
  isMain: boolean
  grouping: Grouping2
  isBetBuilder: boolean
  attr?: string
}

export interface Name4 {
  value: string
  sign: string
}

export interface Result {
  id: number
  odds: number
  // color?: string
  name: Name5
  sourceName?: SourceName2
  visibility: string
  numerator: number
  denominator: number
  americanOdds: number
  attr?: string
  totalsPrefix?: string
}

export interface Name5 {
  value: string
  sign: string
}

export interface SourceName2 {
  value: string
  sign: string
}

export interface TemplateCategory2 {
  id: number
  name: Name6
  category: string
}

export interface Name6 {
  value: string
  sign: string
}

export interface Grouping2 {
  gridGroups: string[]
  detailed: Detailed2[]
}

export interface Detailed2 {
  subIndex: number
  marketTabId: number
  optionColumnId?: number
  name?: string
  displayType: string
  marketGroupId: string
  marketGroupItemId: string
  orderType: string
}

export interface Participant {
  participantId: number
  name: Name7
  image?: Image
  options: any[]
  source?: string
  properties?: Properties
}

export interface Name7 {
  value: string
  sign: string
  short: string
  shortSign: string
}

export interface Image {
  logo: string
  rotateJersey: boolean
  logoColor: string
  lightColor?: string
  darkColor?: string
  isParticipantProfile: boolean
}

export interface Properties {
  type: string
  team: number
}

export interface Name8 {
  value: string
  sign: string
}

export interface Description {
  value: string
  sign: string
}

export interface Addons {
  betRadar: number
  statistics: boolean
  participantDividend: ParticipantDividend
  liveMatchTracker: LiveMatchTracker
  sportcastId?: number
  betBuilderConsumerFixtureId?: string
  fixtureStatistics: FixtureStatistics
  angstromBetBuilderId?: string
}

export interface ParticipantDividend { }

export interface LiveMatchTracker {
  sportradarId: number
  coverageLevel: number
}

export interface FixtureStatistics {
  seasonStandings?: SeasonStandings
  startingPitcher?: StartingPitcher
}

export interface SeasonStandings {
  homeTeam: HomeTeam
  awayTeam: AwayTeam
}

export interface HomeTeam {
  wonEvents: number
  lostEvents: number
  drawEvents: number
}

export interface AwayTeam {
  wonEvents: number
  lostEvents: number
  drawEvents: number
}

export interface StartingPitcher {
  homeTeam?: HomeTeam2
  awayTeam: AwayTeam2
}

export interface HomeTeam2 {
  firstName: string
  lastName: string
  preferredName: string
}

export interface AwayTeam2 {
  firstName: string
  lastName: string
  preferredName: string
}

export interface Sport {
  type: string
  id: number
  name: Name9
}

export interface Name9 {
  value: string
  sign: string
}

export interface Competition {
  parentLeagueId: number
  statistics: boolean
  sportId: number
  compoundId: string
  type: string
  id: number
  parentId: number
  name: Name10
  imageProfile?: ImageProfile
}

export interface Name10 {
  value: string
  sign: string
}

export interface ImageProfile {
  logo: string
  logoColor: string
}

export interface Region {
  code: string
  sportId: number
  type: string
  id: number
  parentId: number
  name: Name11
}

export interface Name11 {
  value: string
  sign: string
}

export interface MarketGroups {
  outrightMarketGroupIds: any[]
  specialMarketGroupIds: any[]
  type: string
  id: number
}

export interface Teaserbet {
  crossSportIds: number[]
  crossLeagueIds: number[]
}

export interface HybridFixtureData {
  fixtureId: string
  status: string
}


//Get all Prematch bets in HomePage by tournamentId and widgetId (use api BetMGM)
export const getHomeBetBetMGM = async (priceStyle: string = "Decimal", date?: string, timeZone?: string) => {
  const path = "api/v1/home-bet-betmgm"
  const params = { priceStyle, date: date, timeZone }
  const result: ResponseSuccess<InfoBetMGM[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

// all Prematch and Live bets by tournamentId and widgetId (use api BetMGM)
export const getAllBetMGM = async (tournamentName: string, timeZone?: string, date?: string, tournaments?: number[] | string) => {
  const path = "api/v1/all-bet-betmgm"
  const params = { tournamentName, timeZone, date, tournaments }
  const result: ResponseSuccess<InfoBetMGM[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}
export interface InfoTagNameListCountryTournamentBetBetMGM {
  value: string
  sign?: string
  icon: string
}

export interface InfoTagListCountryTournamentBetBetMGM {
  type: string
  id: number
  name: InfoTagNameListCountryTournamentBetBetMGM
}

export interface InfoExtendedListCountryTournamentBetBetMGM {
  gridable: number
  outrights?: number
  other: number
  coupons?: boolean
  outrightsByGroup: any
  specialsByGroup: any
  priceBoost: number
}
export interface InfoTagCountriesListCountryTournamentBetBetMGM {
  code?: string
  sportId: number
  sport?: string
  region?: string
  type: string
  id: number
  parentId: number
  name: InfoTagNameListCountryTournamentBetBetMGM
}

export interface InfoCountriesListCountryTournamentBetBetMGM {
  tag: InfoTagCountriesListCountryTournamentBetBetMGM
  live: number
  preMatch: number
  liveStreaming: number
  antepost: number
  extended: InfoExtendedListCountryTournamentBetBetMGM
}

export interface InfoTournamentsListCountryTournamentBetBetMGM {
  tag: InfoTagCountriesListCountryTournamentBetBetMGM
  live: number
  preMatch: number
}

export interface InfoListCountryTournamentBetBetMGM {
  tag: InfoTagListCountryTournamentBetBetMGM
  live: number
  preMatch: number
  liveStreaming: number
  antepost: number
  extended: InfoExtendedListCountryTournamentBetBetMGM
  rank: number
  countries: InfoCountriesListCountryTournamentBetBetMGM[]
  tournaments: InfoTournamentsListCountryTournamentBetBetMGM[]
}

export const listCountryTournamentBetBetMGM = async () => {
  const path = "api/v1/list-sport-country-bet-betmgm"
  const params = {}
  const result: ResponseSuccess<InfoListCountryTournamentBetBetMGM[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export const liveBetBetmgm = async (tournamentName: string) => {
  const path = "api/v1/live-bet-betmgm"
  const params = { tournamentName }
  const result: ResponseSuccess<InfoBetMGM[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export const allLiveBetBetMGM = async () => {
  const path = "api/v1/all-live-bet-betmgm"
  const params = {}
  const result: ResponseSuccess<InfoBetMGM[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

// Competition
export interface InfoListTournamentBetMGM {
  tag: Competition
  live: number
  preMatch: number
  liveStreaming: number
  antepost: number
  extended: InfoExtendedListCountryTournamentBetBetMGM
  rank: number
  bet?: InfoBetMGM[] // info từng trận
}


export const listTournamentBetBetMGM = async (sportName?: string, countryName?: string) => {
  const path = "api/v1/list-tournament-bet-betmgm"
  const params = { sportName, countryName }
  const result: ResponseSuccess<InfoListTournamentBetMGM[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export const prematchBetBetMGM = async (tournamentName: string, date?: string | undefined, timeZone?: string) => {
  const path = "api/v1/prematch-bet-betmgm"
  const params = { tournamentName, date, timeZone }
  const result: ResponseSuccess<InfoBetMGM[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export interface infoListDate {
  feild: string;
  date: null | string;
  value?: string;
  sbobet?: Sbobet;
  totalMatch?: number
}

export interface Sbobet {
  value: string;
}

export const listDate = async (timeZone?: string, sportName?: string, tournamentName?: string, tournaments?: number[] | string) => {
  const path = "api/v1/list-date"
  const params = { timeZone, sportName, tournamentName, tournaments }
  const result: ResponseSuccess<infoListDate[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}


export const listDateSoccer = async (timeZone?: string) => {
  const path = "api/v1/list-date-soccer"
  const params = { timeZone }
  const result: ResponseSuccess<infoListDate[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export interface InfoTournamentSoccer_V2 {
  tournament: string
  key: any
  icon?: string
  data?: InfoTournamentChildren[]
  totalMatch?: number
}

export interface InfoTournamentChildren {
  key: string
  label: string
  icon: string
  amount: number
}

export const prematchTournamentSoccerSbobet = async (date?: string, timeZone?: string) => {
  const path = "api/v1/prematch-tournament-soccer-sbobet"
  const params = { date, timeZone }
  const result: ResponseSuccess<InfoTournamentSoccer_V2[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export const allTournamentSoccerSbobet = async (date?: string, timeZone?: string, tournaments?: number[] | string) => {
  const path = "api/v1/all-tournament-soccer-sbobet"
  const params = { date, timeZone }
  const result: ResponseSuccessTotalMatch<InfoTournamentSoccer_V2[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export const liveTournamentSoccerSbobet = async () => {
  const path = "api/v1/live-tournament-soccer-sbobet"
  const params = {}
  const result: ResponseSuccess<InfoTournamentSoccer_V2[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export const tournamentsSameSport = async (tournamentName: string, sportName?: string) => {
  const path = "api/v1/tournaments-same-sport"
  const params = { tournamentName, sportName }
  const result: ResponseSuccess<InfoTournamentSameSport[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}
export interface InfoTournamentSameSport {
  id_tournament: number;
  tournament: string;
  id_region: number;
  region: string;
  id_sport: number;
  sport: string;
}

export interface InfoGameLiveSoccerV1 {
  id: number;
  gameId: string;
  leagueTitle: string;
  homeTeam: string;
  awayTeam: string;
  homeScore: string;
  awayScore: string;
  liveDisplayTime: string;
  additionalTime: null | any;
  createTime: string;
  tmpLock: boolean;
  // details: any[];
  details: Detail[];
  detailsPrevious?: Detail[];
  liveSingleBetTypeTitle: LiveSingleBetTypeTitle[]
  liveSingleBetTypeTitlePrevious: LiveSingleBetTypeTitle[]
  liveFastMarketTitle: any[]
  todaySingleBetTypeTitle?: LiveSingleBetTypeTitle[]
  todaySingleBetTypeTitlePrevious?: LiveSingleBetTypeTitle[]
}

export interface Detail {
  id: number;
  detailGameId: number | string;
  sourceDetailId?: number | string;
  rowIndexKey: string;
  fA12OddsTag: null | string;
  fA12OddsPrice: null | string;
  fHPDOddsPoint: string | null;
  fHPDOddsPointCal: string | null;
  fHPDOddsPrice: null | string;
  fOUOddsPoint: null | string;
  fOUOddsTag: string | null;
  fOUOddsPointCal: string | null;
  fOUOddsPrice: null | string;
  f12OddsTag: null | string;
  f12OddsPrice: null | string;
  hTHPDOddsPoint: string | null;
  hTHPDOddsPointCal: null | string;
  hTHPDOddsPrice: null | string;
  hTOUOddsPoint: null | string;
  hTOUOddsPointCal: null | string;
  hTOUOddsTag: string | null;
  hTOUOddsPrice: null | string;
  hT12OddsTag: null | string;
  hT12OddsPrice: null | string;
  nextGoal: null | string;
  createTime: string;
}
export interface LiveSingleBetTypeTitle {
  id: number
  rowIndexKey: string
  singleBetTypeTitleId: string
  title: string
  optionText: string
  odds: string
  createTime: string
}

export const allGameByTournamentSoccerSbobet = async (tournamentName?: string, date?: string, timeZone?: string) => {
  const path = "api/v1/get-all-game-by-tournament-soccer-sbobet"
  const params = { tournamentName, date, timeZone }
  const result: ResponseSuccess<InfoGameTournamentSoccer[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export interface InfoGameTournamentSoccer {
  id: number
  eventCode: string
  eventDate: string
  hasStatistics: boolean
  liveCourtMatchId: number
  showTime: string
  showTimeType: number
  tvChannels: any[]
  marketTypeCount: string[]
  isLive: boolean
  realKickoffTime: string
  mainMarketEventResult: MainMarketEventResultSoccer
  awayTeam: AwayTeamSoccer
  homeTeam: AwayTeamSoccer
  tournament: TournamentSoccer
  stream: any
  nonLiveOutrightIds: any[]
}

export interface MainMarketEventResultSoccer {
  liveAwayScore: number
  liveHandicapType: string
  nonLiveHandicapType: string
  liveHomeScore: number
  isMixParlay: boolean
  extraInfo: any
}

export interface AwayTeamSoccer {
  teamName: string
}

export interface TournamentSoccer {
  id: number
  tournamentName: string
  tournamentInfo: string
  tournamentSize: string
  sport: SportSoccer
}

export interface SportSoccer {
  sportType: string
}

//Get game by tournament id
export const getGameSoccerByTournamentName = async (tournamentName: string, date: string = "Today", timeZone?: string, tournaments?: number[] | string) => {
  const path = "api/v1/get-game-prematch-by-tournament-soccer-sbobet"
  const params = {
    tournamentName,
    date,
    timeZone,
    tournaments
  }
  const result: ResponseSuccess<InfoGameSoccer[]> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

export const getGameSoccerLiveByTournamentName = async (tournamentName: string) => {
  const path = "api/v1/get-game-live-by-tournament-soccer-sbobet"
  const params = {
    tournamentName
  }
  const result: ResponseSuccess<InfoGameSoccer[]> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

export interface InfoGameSoccer {
  id: number
  eventCode: string
  eventDate: string
  hasStatistics: boolean
  liveCourtMatchId: number
  showTime: string
  showTimeClient: any
  showTimeClientTest: any
  subtractTimeClient: any
  showTimeType: number
  tvChannels: any[]
  marketTypeCount: string[]
  isLive: boolean
  realKickoffTime: string
  mainMarketEventResult: MainMarketEventResult
  awayTeam: AwayTeam
  homeTeam: HomeTeam
  homeScore?: string;
  awayScore?: string;
  tournament: Tournament
  stream: any
  nonLiveOutrightIds: any[]
  time?: any
  odds?: Odd[]
  oddsOld?: Odd[]
  countNumberSubbet?: number
}
export interface Odd {
  id: number
  marketType: string
  point: number
  prices: Price[]
}

export interface MainMarketEventResult {
  liveAwayScore: number
  liveHandicapType: string
  nonLiveHandicapType: string
  liveHomeScore: number
  isMixParlay: boolean
  extraInfo: any
  subMarketEventResult?: any
}

export interface AwayTeam {
  teamName: string
}

export interface HomeTeam {
  teamName: string
}

export interface Tournament {
  id: number
  tournamentName: string
  tournamentInfo: string
  tournamentSize: string
  sport: Sport
}

export interface Sport {
  sportType: string
}



export const getOddsSoccerByGameId = async (gameId: number) => {
  const path = "api/v1/get-odds-soccer-sbobet"
  const params = {
    gameId
  }
  const result: ResponseSuccess<InfoGameDetailSoccer[]> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

export interface InfoGameDetailSoccer {
  id: number
  isLive: boolean
  hasMixParlay: boolean
  point: number
  sport: string
  status: string
  betCondition: string
  sortOrder: number
  marketType: string
  prices: Price[]
  eventResult: EventResult
}

export interface Price {
  price: number
  option: string
}

export interface EventResult {
  id: number
  eventId: number
  liveHandicapType: string
  nonLiveHandicapType: string
  marketGroup: MarketGroup
}

export interface MarketGroup {
  id: number
  marketGroupName: string
  homeTemplate: string
  awayTemplate: string
  homeExtension: string
  awayExtension: string
  displayPriority: number
  isSingle: boolean
  isYesNo: boolean
}

export interface AllTournamentSoccer {
  id: number;
  pollId: string;
  name: string;
  createTime: string;
}
